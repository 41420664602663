let loadingCount = 0;
let loadingTimer = null;
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import { authApi } from "@/api";

export const getRefreshToken = () => {
  const params = {
    refreshJwt: "",
  };
  switch (store.getters.adminType) {
    case "0":
      params.refreshJwt = localStorage.getItem("adminRefreshJwt");
      break;
    case "1":
      params.refreshJwt = localStorage.getItem("platformRefreshJwt");
      break;

    default:
      break;
  }
  return new Promise((resolve, reject) => {
    authApi
      .reflushAccount(params)
      .then((res) => {
        console.log(res);
        const token = res.data.jwt;
        const refreshJwt = res.data.refreshJwt;
        store.dispatch("setToken", token);
        switch (store.getters.adminType) {
          case "0":
            store.dispatch("setAdminToken", token);
            localStorage.setItem("adminToken", token);
            localStorage.setItem("adminRefreshJwt", refreshJwt);
            break;
          case "1":
            store.dispatch("setPlatformToken", token);
            localStorage.setItem("platformToken", token);
            localStorage.setItem("platformRefreshJwt", refreshJwt);
            break;

          default:
            break;
        }
        resolve(token);
        // localStorage.setItem("token", res.data.jwt);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const checkMenu = (menu) => {
  const router = useRouter();
  const route = useRoute();
  const action = route.meta.action;
  const publicPage = route.meta.publicPage;
  const menuSarech = menu.find((item) => {
    return item.href === action;
  });
  if (!menuSarech && !publicPage) {
    // 既没有匹配到菜单，又不是公开页面
    if (menu.length > 0) {
      // 菜单中有数据
      console.warn("no menu match go 0index menu");
      switch (store.getters.adminType) {
        case "0":
          router.replace({ name: "Dashboard" });
          break;
        case "1":
          router.replace({ name: "PlatformDashboard" });
          break;
        default:
          break;
      }
    } else {
      console.warn("no menu match go login");
      switch (store.getters.adminType) {
        case "0":
          router.replace({ name: "Login" });
          break;
        case "1":
          router.replace({ name: "PlatformLogin" });
          break;
        default:
          break;
      }
    }
  }
  console.log(menuSarech);
};

const startLoading = () => {
  store.dispatch("setLoading", true);
};

const endLoading = () => {
  store.dispatch("setLoading", false);
};

export const showLoading = () => {
  clearTimeout(loadingTimer);
  loadingTimer = setTimeout(() => {
    loadingCount = 1;
    hideLoading();
  }, 30000);
  if (loadingCount === 0) {
    startLoading();
  }
  loadingCount += 1;
};

export const hideLoading = () => {
  if (loadingCount <= 0) {
    return;
  }
  loadingCount -= 1;
  if (loadingCount === 0) {
    clearTimeout(loadingTimer);
    endLoading();
  }
};

export const checkForm = (form, rule) => {
  const errorInfo = {};
  Object.keys(rule).forEach(function (key) {
    rule[key].forEach((item) => {
      const type = item.type;
      const label = item.label;
      const value = form[key];
      switch (type) {
        case "required":
          if (!value) {
            errorInfo[key] = `${label}は必要項目です`;
          }
          break;
        case "confirmed":
          const target = item.target;
          if (value !== form[target]) {
            errorInfo[key] = `${label}が一致しません`;
          }
          break;

        case "dateAfter":
          const dateAfterTarget = item.target;
          if (form[dateAfterTarget]) {
            if (
              new Date(value).getTime() <=
              new Date(form[dateAfterTarget]).getTime()
            ) {
              errorInfo[key] = `${label}の日時が間違っています`;
            }
          }
          break;
        case "lengthCheck":
          const lengthCheckMin = item.min;
          const lengthCheckMax = item.max;
          const lengthCheckValue = value || "";
          if (lengthCheckValue.length < lengthCheckMin) {
            errorInfo[key] = `${label}は${item.min}文字以上です`;
          }
          if (lengthCheckValue.length > lengthCheckMax) {
            errorInfo[key] = `${label}は${item.min}文字以内です`;
          }
          break;
        case "arrayRequired":
          if (value.length === 0) {
            errorInfo[key] = `${label}は必要項目です`;
          }
          break;
        default:
          break;
      }
    });
  });
  return errorInfo;
};
export const nicnNameSubstring = (nickName) => {
  return nickName.substring(0, 2);
};
export const creatColorByNickName = (NickName) => {
  // nick name to code
  let id = 0;
  for (var i = 0; i < NickName.length; i++) {
    id += NickName.charCodeAt(i);
  }
  id = id % 100;
  const h = id * (360 / 100);
  const s = 80;
  const l = 30;
  return hslToHex(h, s, l);
};
export const hslToHex = (h, s, l) => {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0"); // convert to Hex and prefix "0" if needed
  };
  return `${f(0)}${f(8)}${f(4)}`;
};
export const urlToBlob = (url) => {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        resolve(URL.createObjectURL(blob));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const downloadByCos = (res, downloadRef) => {
  const fileName = res.data.match(".+/(.+?)([\?#;].*)?$")[1]
  authApi.downloadCos(res.data).then((res) => {
    const fileURL = window.URL.createObjectURL(
      new Blob([res.data], { type: 'application/octet-stream' })
    )
    console.log(fileURL)
    downloadRef.value.href = fileURL
    downloadRef.value.setAttribute('download', fileName)
    downloadRef.value.click()
    window.URL.revokeObjectURL(fileURL)
  })

}

export const uuid = () => {
  let chars = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".split("");
  for (let i = 0, len = chars.length; i < len; i++) {
    switch (chars[i]) {
      case "x":
        chars[i] = Math.floor(Math.random() * 16).toString(16);
        break;
      case "y":
        chars[i] = (Math.floor(Math.random() * 4) + 8).toString(16);
        break;
    }
  }
  return chars.join("");
}