import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Login.vue"),
    meta: {
      publicPage: true,
      adminType: "0",
    },
  },
  {
    path: "/platform/login",
    name: "PlatformLogin",
    component: () =>
      import(/* webpackChunkName: "PlatformLogin" */ "../views/Login.vue"),
    meta: {
      publicPage: true,
      adminType: "1",
    },
  },
  {
    path: "/",
    name: "Home",
    meta: {
      publicPage: false,
      adminType: "0",
    },
    component: () =>
      import(/* webpackChunkName: "Admin" */ "../views/admin/Index.vue"),
  },
  {
    path: "/platform",
    name: "Index",
    meta: {
      publicPage: false,
      adminType: "1",
    },
    component: () =>
      import(/* webpackChunkName: "Admin" */ "../views/admin/Index.vue"),
  },
  {
    path: "/admin",
    name: "Admin",
    meta: {
      publicPage: false,
      adminType: "0",
    },
    component: () =>
      import(/* webpackChunkName: "Admin" */ "../views/admin/Index.vue"),
    children: [
      {
        path: "/artists",
        name: "ArtistsList",
        component: () =>
          import(
            /* webpackChunkName: "ArtistsList" */ "../views/admin/artists/ArtistsList.vue"
          ),
        meta: {
          publicPage: false,
          adminType: "0",
          action: "/artists",
        },
      },
      {
        path: "/events",
        name: "EventList",
        component: () =>
          import(
            /* webpackChunkName: "EventList" */ "../views/admin/events/EventList.vue"
          ),
        meta: {
          publicPage: false,
          adminType: "0",
          action: "/events",
        },
      },
      {
        path: "/virtual",
        name: "VirtualList",
        component: () =>
            import(
                /* webpackChunkName: "VirtualList" */ "../views/admin/virtual/VirtualList.vue"
                ),
        meta: {
          publicPage: false,
          adminType: "0",
          action: "/virtual",
        },
      },
      {
        path: "/events/:id/inquiries",
        name: "EventInquiryList",
        component: () =>
          import(
            /* webpackChunkName: "EventInquiryList" */ "../views/admin/Inquiry/InquiryList.vue"
          ),
        meta: {
          publicPage: false,
          adminType: "0",
          action: "/events",
        },
      },
      {
        path: "/banlist",
        name: "BanList",
        component: () =>
          import(
            /* webpackChunkName: "BanList" */ "../views/admin/fans/BanList.vue"
          ),
        meta: {
          publicPage: false,
          adminType: "0",
          action: "/banlist",
        },
      },
      {
        path: "/gifts",
        name: "GiftGroupList",
        component: () =>
          import(
            /* webpackChunkName: "GiftGroupList" */ "../views/admin/gifts/GiftGroupList.vue"
          ),
        meta: {
          publicPage: false,
          adminType: "0",
          action: "/gifts",
        },
      },
      {
        path: "/events/:id/fans",
        name: "FanList",
        component: () =>
          import(
            /* webpackChunkName: "FanList" */ "../views/admin/fans/FanList.vue"
          ),
        meta: {
          publicPage: false,
          adminType: "0",
          action: "/events",
        },
      },
      {
        path: "/events/:id/videos",
        name: "VideoList",
        component: () =>
          import(
            /* webpackChunkName: "VideoList" */ "../views/admin/videos/VideoList.vue"
          ),
        meta: {
          publicPage: false,
          adminType: "0",
          action: "/events",
        },
      },
      {
        path: "/monitor",
        name: "MonitorList",
        component: () =>
          import(
            /* webpackChunkName: "MonitorList" */ "../views/admin/monitor/MonitorList.vue"
          ),
        meta: {
          publicPage: false,
          adminType: "0",
          action: "/monitor",
        },
      },
      {
        path: "/monitor/:id",
        name: "MonitorDetail",
        component: () =>
          import(
            /* webpackChunkName: "MonitorDetail" */ "../views/admin/monitor/MonitorDetail.vue"
          ),
        meta: {
          publicPage: false,
          adminType: "0",
          action: "/monitor",
        },
      },
      {
        path: "/monitor/live/:id",
        name: "MonitorLiveDetail",
        component: () =>
          import(
            /* webpackChunkName: "MonitorLiveDetail" */ "../views/admin/monitor/MonitorLiveDetail.vue"
          ),
        meta: {
          publicPage: false,
          adminType: "0",
          action: "/monitor",
        },
      },
      {
        path: "/dashboard",
        name: "Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "../views/admin/dashboard/Dashboard.vue"
          ),
        meta: {
          publicPage: false,
          adminType: "0",
          action: "/monitor",
        },
      },
      {
        path: "/platform/dashboard",
        name: "PlatformDashboard",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "../views/admin/dashboard/Dashboard.vue"
          ),
        meta: {
          publicPage: false,
          adminType: "1",
          action: "/platform/dashboard",
        },
      },
      {
        path: "/platform/admins",
        name: "AdminList",
        component: () =>
          import(
            /* webpackChunkName: "AdminList" */ "../views/admin/admins/AdminList.vue"
          ),
        meta: {
          publicPage: false,
          adminType: "1",
          action: "/platform/admins",
        },
      },
      {
        path: "/platform/organizations",
        name: "OrganizationList",
        component: () =>
          import(
            /* webpackChunkName: "OrganizationList" */ "../views/admin/organizations/OrganizationList.vue"
          ),
        meta: {
          publicPage: false,
          adminType: "1",
          action: "/platform/organizations",
        },
      },
      {
        path: "/platform/organizations/setting/:id",
        name: "organizationSetting",
        component: () =>
          import(
            /* webpackChunkName: "organizationSetting" */ "../views/admin/organizations/setting/Setting.vue"
          ),
        meta: {
          publicPage: false,
          adminType: "1",
          action: "/platform/organizations",
        },
        children: [
          {
            //默认
            path: "",
            redirect: { name: "organizationSettingSceneList" },
          },
          {
            path: "scenelist",
            name: "organizationSettingSceneList",
            component: () =>
              import(
                /* webpackChunkName: "organizationSettingSceneList" */ "../views/admin/organizations/setting/SceneList.vue"
              ),
            meta: {
              publicPage: false,
              adminType: "1",
              action: "/platform/organizations",
              menuActiveIndex: 0,
            },
          },
          {
            path: "frameList",
            name: "organizationSettingFrameList",
            component: () =>
              import(
                /* webpackChunkName: "organizationSettingFrameList" */ "../views/admin/organizations/setting/FrameList.vue"
              ),
            meta: {
              publicPage: false,
              adminType: "1",
              action: "/platform/organizations",
              menuActiveIndex: 1,
            },
          },
        ],
      },
      {
        path: "/platform/theme",
        name: "OfficeList",
        component: () =>
          import(
            /* webpackChunkName: "officeList" */ "../views/admin/office/OfficeList.vue"
          ),
        meta: {
          publicPage: false,
          adminType: "1",
          action: "/platform/organizations",
        },
      },
      // office设定
      {
        path: "/platform/office/setting/:id",
        name: "OfficeSetting",
        component: () =>
          import(
            /* webpackChunkName: "OfficeSetting" */ "../views/admin/office/setting/Setting.vue"
          ),
        meta: {
          publicPage: false,
          adminType: "1",
          action: "/platform/theme",
        },
        children: [
          {
            //默认
            path: "",
            redirect: { name: "Theme" },
          },
          {
            path: "theme",
            name: "Theme",
            component: () =>
              import(
                /* webpackChunkName: "OfficeSetting" */ "../views/admin/office/setting/Theme.vue"
              ),
            meta: {
              publicPage: false,
              adminType: "1",
              action: "/platform/theme",
              menuActiveIndex: 0,
            },
          },
        ],
      },
      {
        path: "/platform/enquetes",
        name: "InquiryIndex",
        component: () =>
          import(
            /* webpackChunkName: "InquiryIndex" */ "../views/admin/Inquiry/Index.vue"
          ),
        meta: {
          publicPage: false,
          adminType: "1",
          action: "/platform/enquetes",
        },
        children: [
          {
            path: "",
            name: "InquiryEventList",
            component: () =>
              import(
                /* webpackChunkName: "InquiryEventList" */ "../views/admin/Inquiry/EventList.vue"
              ),
            meta: {
              publicPage: false,
              adminType: "1",
              action: "/platform/enquetes",
            },
          },
          {
            path: ":id/inquiries",
            name: "InquiryList",
            component: () =>
              import(
                /* webpackChunkName: "InquiryList" */ "../views/admin/Inquiry/InquiryList.vue"
              ),
            meta: {
              publicPage: false,
              adminType: "1",
              action: "/platform/enquetes",
            },
          },
        ],
      },
    ],
  },
  { path: "/:pathMatch(.*)*", redirect: "/login" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  const publicPage = to.meta.publicPage;
  let token = null;
  const adminType = to.meta.adminType;
  switch (adminType) {
    case "0":
      token = store.getters.adminToken;
      break;
    case "1":
      token = store.getters.platformToken;
      break;
    default:
      break;
  }
  store.dispatch("setToken", token);
  store.dispatch("setAdminType", adminType);
  if (!publicPage) {
    if (!token) {
      console.warn("route check no token go login");
      switch (adminType) {
        case "0":
          router.replace({ name: "Login" });
          break;
        case "1":
          router.replace({ name: "PlatformLogin" });
          break;
        default:
          break;
      }
    }
  }

  next();
});
export default router;
