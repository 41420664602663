<template>
  <ConfirmDialog></ConfirmDialog>
  <div class="common-loading" v-if="loadingShow">
    <div class="common-loading-content">
      <div><i class="pi pi-spin pi-spinner" style="fontSize: 2rem"></i></div>
      <div class="pl10">Loading...</div>
    </div>
  </div>
  <router-view />
  <Toast :autoZIndex="false" class="top-index-99999" />
</template>
<script>
import Toast from "primevue/toast";
import ConfirmDialog from "primevue/confirmdialog";
import { computed } from "vue";
import store from "@/store";
export default {
  components: { Toast, ConfirmDialog },
  setup() {
    const loadingShow = computed(() => {
      return store.getters.loadingShow;
    });
    return { loadingShow };
  },
};
</script>
