import { createStore } from "vuex";
import { authApi } from "@/api";

export default createStore({
  state: {
    token: localStorage.getItem("token") || "",
    platformToken: localStorage.getItem("platformToken") || "",
    adminToken: localStorage.getItem("adminToken") || "",
    adminType: "",
    adminInfo: null,
    menuData: null,
    loadingShow: false,
    serverDelay: 0,
    checkDelayOk: false,
    nowTime: new Date().getTime(),
  },
  getters: {
    token: (state) => state.token,
    platformToken: (state) => state.platformToken,
    adminToken: (state) => state.adminToken,
    menuData: (state) => state.menuData,
    adminType: (state) => state.adminType,
    adminInfo: (state) => state.adminInfo,
    loadingShow: (state) => state.loadingShow,
    serverDelay: (state) => state.serverDelay,
    checkDelayOk: (state) => state.checkDelayOk,
    nowTime: (state) => state.nowTime,
  },
  mutations: {
    setNowTime(state) {
      state.nowTime = new Date().getTime() - state.serverDelay;
    },
    setMenuData(state, data) {
      state.menuData = data;
    },
    setToken(state, data) {
      state.token = data;
    },
    setPlatformToken(state, data) {
      state.platformToken = data;
    },
    setAdminToken(state, data) {
      state.adminToken = data;
    },
    setAdminType(state, data) {
      state.adminType = data;
    },
    setAdminInfo(state, data) {
      state.adminInfo = data;
    },
    setLoadingShow(state, data) {
      state.loadingShow = data;
    },
    setLoading(state, data) {
      state.loadingShow = data;
    },
    async checkDelay(state) {},
  },
  actions: {
    setNowTime({ commit }) {
      commit("setNowTime");
    },
    setMenuData({ commit }, data) {
      commit("setMenuData", data);
    },
    setToken({ commit }, data) {
      commit("setToken", data);
    },
    setPlatformToken({ commit }, data) {
      commit("setPlatformToken", data);
    },
    setAdminToken({ commit }, data) {
      commit("setAdminToken", data);
    },
    setAdminType({ commit }, data) {
      commit("setAdminType", data);
    },
    setAdminInfo({ commit }, data) {
      commit("setAdminInfo", data);
    },
    setLoadingShow({ commit }, data) {
      commit("setLoadingShow", data);
    },
    setLoading({ commit }, data) {
      commit("setLoading", data);
    },
    checkDelay({ commit }) {
      // debugger
      commit("checkDelay");
    },
  },
  modules: {},
});
