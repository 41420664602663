import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/common.css";
import "primevue/resources/primevue.min.css";
import "./assets/css/theme.css";
import "primeicons/primeicons.css";
import "cropperjs/dist/cropper.css";
import "primeflex/primeflex.css";
import VueSidebarMenu from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import Tooltip from 'primevue/tooltip';
import Popper from "vue3-popper";

const app = createApp(App);
app.component("Popper", Popper);
app
  .use(store)
  .use(router)
  .use(PrimeVue, {
    locale: {
      dayNames: [
        "日曜日",
        "月曜日",
        "火曜日",
        "水曜日",
        "木曜日",
        "金曜日",
        "土曜日",
      ],
      dayNamesShort: ["日", "月", "火", "水", "木", "金", "土"],
      dayNamesMin: ["日", "月", "火", "水", "木", "金", "土"],
      monthNames: [
        "1月",
        "2月",
        "3月",
        "4月",
        "5月",
        "6月",
        "7月",
        "8月",
        "9月",
        "10月",
        "11月",
        "12月",
      ],
      monthNamesShort: [
        "1月",
        "2月",
        "3月",
        "4月",
        "5月",
        "6月",
        "7月",
        "8月",
        "9月",
        "10月",
        "11月",
        "12月",
      ],
      today: "今日",
      dateFormat: "yy/mm/dd",
      clear: "クリア",
      accept: "はい",
      reject: "いいえ",
    },
  })
  .use(ToastService)
  .use(ConfirmationService)
  .use(VueSidebarMenu).directive('tooltip', Tooltip)
  .mount("#app");
app.config.globalProperties.$getUrlTime = () => {
  return `t=${new Date().getTime()}`;
};
window["$getUrlTime"] = app.config.globalProperties.$getUrlTime
console.log = (function (log) {
  return process.env.VUE_APP_NO_CONSOLE == "1" ? function () { } : log;
})(console.log);
